import React from "react"
import { Helmet } from "react-helmet"
import "./events.css"
import "../components/partner.css"
import 'bootstrap/dist/css/bootstrap.min.css'

import AsecLogo from "../images/logos/asec-logo.jpg"
import EmmaHead from "../images/gatsby-icon.png"
import event_img from '../images/events-img/card-img/ws-saude-landscape-min.jpg'
import MyFooter from "../components/footer.js"
import MyNavbar from "../components/navbar.js"
import EventBanner from "../components/event-banner.js"


const EventsPage = () => (
  <div className="index">
    <Helmet>
      <title>WS Saúde Emocional</title>
      <link rel="icon" href={EmmaHead} />
    </Helmet>
      <MyNavbar />
      <EventBanner 
        mobile_img = {event_img}
        img = {event_img}
        name = "Workshop de Saúde Emocional para Mulheres (ONG ASEC)"
        date = "Agosto/2019"
        description = "Workshop de saúde emocional com foco em mulheres em STEM, realizado em parceria com a ONG Associação pela Saúde Emocional de Crianças (ASEC). A atividade, que buscou abordar ferramentas para se trabalhar o bem estar emocional e discutir as dificuldades vivenciadas pela população feminina, teve duração de 2h e atingiu  11 mulheres da Escola Politécnica da USP."
      />
      <div className="partner-container">
        <div className="partner-title">PARCERIAS</div>
        <div className="partner-flex">
            <div className="partner-element">
                <img src={AsecLogo} alt="" className="partner-logo"/>
                <p className="partner-name">ASEC</p>
            </div>
        </div>
      </div>
      <MyFooter />
  </div>
)

export default EventsPage
